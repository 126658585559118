<template>
  <div id="home-landing">
    <div class="hero-banner" style="margin-bottom: 10vh">
      <div class="container row mx-auto" style="margin: 30px auto">
        <div class="col-12 col-lg-7 text-start" style="padding: 50px 20px 50px 0">
          <span class="hero-top-subtitle"> Australia’s most </span>
          <h1 style="margin: 35px 0">Cost effective and efficient vehicle appraisal system</h1>
          <p style="margin: 25px 0; padding: 0 50px 0 0; color: #000000">
            iAppraise offers the most progressive automotive appraisal technology on the planet. Automated accurate
            data, live reporting, live tenders, DMS integration, PPSR ordering, all in the palm of your hand.
          </p>

          <BookDemoButton />
        </div>
      </div>
    </div>
    <div style="margin-bottom: 10vh">
      <div class="container">
        <h2 class="text-center m-5">iAppraise Key Features</h2>
        <div class="features">
          <div class="container row mx-auto">
            <div class="col-6 features-item" v-for="feature in features" :key="feature.title">
              <div
                class="text-start"
                style="display: flex; flex-direction: row; justify-content: center; align-items: center"
              >
                <div class="icon">
                  <img :src="`/assets/img/${feature.src}`" alt="iAppraise-feature-icons" />
                </div>
                <div style="padding: 0 20px 0 30px">
                  <h3 class="title">{{ feature.title }}</h3>
                  <p>
                    {{ feature.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container row mx-auto" style="padding-bottom: 60px; margin-bottom: 10vh">
      <h2 class="text-center mb-5">Integrated Partners</h2>
      <div class="col text-center">
        <agile autoplay :slidesToShow="4" :navButtons="false">
          <div class="slide" v-for="partner in integratedPartners" :key="partner.src">
            <a :href="partner.url" v-if="partner.url"
              ><img :src="`/assets/img/${partner.src}`" class="img-fluid" alt="partner-logo"
            /></a>
            <img v-else class="img-fluid" :src="`/assets/img/${partner.src}`" alt="partner-logo" />
          </div>
        </agile>
      </div>
    </div>

    <div>
      <div class="container row mx-auto" style="margin-bottom: 10vh">
        <h2 class="text-center">How it Works</h2>
        <div class="how-it-works">
          <div class="container">
            <div class="col step-one">
              <div class="item-first">
                <div class="image">
                  <img src="/assets/img/Step-EnterRego.png" alt="how-Iappraise-works-image" />
                </div>
                <h5>Scan Vin or Enter Rego</h5>
                <p>The App will begin collecting data & specifications from manufacturer databases</p>
              </div>
            </div>
            <div class="col step-two">
              <div class="item-second ms-auto">
                <div class="image">
                  <img src="/assets/img/Step-Collate.png" alt="how-Iappraise-works-image" />
                </div>
                <h5>Collate</h5>
                <p>Salesperson now adds photos & additional information</p>
              </div>
            </div>
            <div class="col step-three">
              <div class="item-third">
                <div class="image">
                  <img src="/assets/img/Step-Circulate.png" alt="how-Iappraise-works-image" />
                </div>
                <h5>Circulate</h5>
                <p>Send appraisal to wholesalers or internal buyers for pricing</p>
              </div>
            </div>
            <div class="col step-four">
              <div class="item-fourth ms-auto">
                <div class="image">
                  <img src="/assets/img/Step-Select.png" alt="how-Iappraise-works-image" />
                </div>
                <h5>Select</h5>
                <p>Lastly, choose the best price & trade your vehicle!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-light pb-0 mb-5">
      <div class="container pb-5 pt-5 mx-auto row justify-content-md-center">
        <div class="col-10 cta-section text-center">
          <h5>Get Started Now</h5>
          <p>
            iAppraise is an integral part of any vehicle dealership that wants to minimise cost, streamline sales
            processes, and maximise revenue. Click on the button below and we will guide you step by step!
          </p>
          <BookDemoButton />
        </div>
      </div>
    </div>

    <div class="container row mx-auto" style="margin-bottom: 10vh">
      <div class="col-6">
        <img style="max-width: 100%" src="/assets/img/peter-drucker.png" alt="iAppraise-peter-drucker-quote" />
      </div>
      <div class="col-6 quote-headings text-start">
        <h5>“You can’t manage what you don’t measure.”</h5>
        <span> Peter Drucker </span>
        <p>World Class Management Expert</p>
      </div>
    </div>

    <div class="testimonials bg-light" style="margin-bottom: 10vh">
      <div class="container row mx-auto">
        <div class="col-12 col-lg-6">
          <div class="container">
            <div class="testimonials-headings">
              <h2 class="text-start mb-0 mb-5--md">Testimonials</h2>
              <h4 class="text-start">Words from our satisfied clients</h4>
              <div class="testimonials-arrows text-end d-none d-md-block">
                <agile :as-nav-for="[$refs.testimonials]">
                  <template v-slot:prevButton
                    ><svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.299878 9.99995C0.299878 9.64151 0.436738 9.28311 0.709884 9.00983L9.30952 0.410283C9.85656 -0.136761 10.7435 -0.136761 11.2903 0.410283C11.8371 0.957105 11.8371 1.84387 11.2903 2.39095L3.68089 9.99995L11.2901 17.609C11.8369 18.156 11.8369 19.0427 11.2901 19.5895C10.7432 20.1368 9.8563 20.1368 9.30926 19.5895L0.709618 10.9901C0.436428 10.7166 0.299878 10.3583 0.299878 9.99995Z"
                        fill="black"
                      /></svg
                  ></template>
                  <template v-slot:nextButton
                    ><svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.7001 9.99995C11.7001 9.64151 11.5633 9.28311 11.2901 9.00983L2.69048 0.410283C2.14344 -0.136761 1.2565 -0.136761 0.709677 0.410283C0.162855 0.957105 0.162855 1.84387 0.709677 2.39095L8.31911 9.99995L0.709942 17.609C0.16312 18.156 0.16312 19.0427 0.709942 19.5895C1.25676 20.1368 2.1437 20.1368 2.69074 19.5895L11.2904 10.9901C11.5636 10.7166 11.7001 10.3583 11.7001 9.99995Z"
                        fill="black"
                      /></svg
                  ></template>
                </agile>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <agile class="testimonials-carousel mx-auto" :navButtons="false" :dots="false" ref="testimonials" autoplay>
            <div class="slide" v-for="testimonial in testimonials" :key="`${testimonial.name}-${testimonial.company}`">
              <div class="item px-4 pt-3">
                <div class="py-3 px-5 mb-5">
                  <p>
                    {{ testimonial.message }}
                  </p>
                  <div class="text-start">
                    <div class="h4 mb-1">{{ testimonial.name }}</div>
                    <div class="desc">{{ testimonial.company }}</div>
                  </div>
                </div>
              </div>
            </div>
          </agile>
        </div>
      </div>
    </div>

    <div class="container row mx-auto" id="faq" style="margin-bottom: 10vh">
      <div class="col-12 col-md-6">
        <h2 class="text-start mx-auto">
          Frequently <br />
          Asked <br />
          Questions
        </h2>
      </div>

      <div class="col-12 col-md-6">
        <BaseAccordion name="faq" :items="faq" />
      </div>
    </div>

    <div class="home-contact">
      <div class="container my-5 py-5">
        <div class="col-7 mx-auto">
          <h2 class="text-center mb-5">Contact us</h2>
          <p class="text-center">For all sales enquiries, or to discuss how we can help your business, contact us.</p>
        </div>
        <ContactForm class="mx-auto" />
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817.6233645270504!2d138.62139742921386!3d-34.9442387987733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cebe3070ffff%3A0x25875c9263a87fb8!2siAppraise!5e0!3m2!1sen!2smy!4v1600327500945!5m2!1sen!2smy"
        height="450"
        frameborder="0"
        style="width: 100%"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import ContactForm from "@/components/Forms/ContactForm.vue";
import "bootstrap";
import BookDemoButton from "@/components/Buttons/BookDemoButton.vue";
import BaseAccordion from "@/components/Accordions/BaseAccordion.vue";

export default {
  name: "HomeLanding",
  components: {
    agile: VueAgile,
    ContactForm,
    BookDemoButton,
    BaseAccordion
  },
  data: function () {
    return {
      form: {},
      features: [
        {
          src: "checklist.svg",
          title: "PPSR",
          description: "Benefit from our government direct API and get fast accurate, cost effective PPSR reports"
        },
        {
          src: "statistics.svg",
          title: "Live Reporting",
          description:
            "Generate reports from real time data that provide your business with accurate, presentable, and useful measurables"
        },
        {
          src: "car.svg",
          title: "DMS integration",
          description: "One click genuine integration with Australia’s leading DMS & CRM providers"
        },
        {
          src: "Integration.svg",
          title: "Automated Data",
          description:
            "We provide the most accurate data available and deliver our clients access to it, all in the palm of their hands"
        },
        {
          src: "iTender.svg",
          title: "iTender functionality",
          description:
            "Live & automated tenders allowing dealers to buy and sell stock easily from anywhere. Monitor or add stock in real time and expand your buyer pool."
        },
        {
          src: "convenient.svg",
          title: "Convenient",
          description:
            "Create or participate in tenders from anywhere, anytime. Build a network of dealers and communicate seamlessly"
        }
      ],
      integratedPartners: [
        {
          src: "e-goodmanners.svg",
          url: ""
        },
        {
          src: "kiratech_logo_white_bg.svg",
          url: "https://kiratech.com.au/"
        },
        {
          src: "partner-autoit.png",
          url: ""
        },
        {
          src: "partner-pentana.png",
          url: ""
        },
        {
          src: "partner-saleslogs.png",
          url: ""
        },
        {
          src: "partner-salesforce.png",
          url: ""
        },
        {
          src: "partner-tune.png",
          url: ""
        },
        {
          src: "partner-redbook.png",
          url: ""
        },
        {
          src: "partner-glass.png",
          url: ""
        }
      ],
      testimonials: [
        {
          message:
            "“The benefits of iAppraise for our network goes beyond the automation of appraising a vehicle – it has given us more buying opportunities for our more profitable used car’s.”",
          name: "Daniel Thomas",
          company: "City Mazda"
        },
        {
          message:
            "“At first glance, we didn’t think a small dealership like us could benefit from the iAppraise system – we were wrong”",
          name: "Shane Beaney",
          company: "Barossa Volkswagen"
        },
        {
          message:
            "“The transition from pen and paper to using the application has been seamless and intuitive. I have been thoroughly impressed by Stephen Gribble and George Grifsas. The technology is sensational, combined with their ongoing support and training makes this an outstanding product.”",
          name: "Harley Whiting",
          company: "Canberra Toyota"
        },
        {
          message:
            "“Since using iAppraise I have seen a significant increase in our trade conversion ratios. Takata airbag alert, convenient built in PPSR and useful management reports has made the system a no brainer.”",
          name: "Jarrod Lindsay",
          company: "Wagga Motors"
        },
        {
          message:
            "“When using iAppraise, you are not just installing an application into your phone. Stephen and George are always available to take our calls, the level of care they have for their clients is second to none. The application is outstanding, it is reliable, affordable and keeps our staff accountable.”",
          name: "George Miklas",
          company: "Lennock Motors"
        },
        {
          message:
            "“iAppraise has revolutionised the way our business trades cars. Live reporting, PPSR, intuitive navigating and Automated Data has made this a cost-effective and vital tool for our business.”",
          name: "Mathew Newey",
          company: "Peter Stevens"
        },
        {
          message:
            "“This system quite simply became a no brainer for us, we would be happy to refer any dealer network that is interested in efficiency, reducing errors and having access to practical, measurable management systems.",
          name: "Barrie Raymond",
          company: "Blood Motor Group"
        }
      ],
      faq: [
        {
          title: "What is iAppraise?",
          description: `iAppraise is the most cost effective and efficient appraisal system in Australia. iAppraise has DMS integration, live reporting, Automated Data, PPSR searches and Takata airbag alerts. This system is an integral part of any dealership that wants to minimise costs, streamline sales processes and maximise revenue.`
        },
        {
          title: "Benefits of iAppraise",
          description: `<ul> <li>Automated Data</li> <li>PPSR convenience</li> <li>DMS Integration</li> <li>Takata airbag alerts</li> <li>Live reporting</li> </ul>`
        },
        {
          title: "History of iAppraise",
          description: `Our founders share over 45 years experience in the automotive industry, we understand the automotive landscape intimately. iAppraise takes the guess work and stress out of appraising a vehicle and maximises its trade price. We have partnered with Australia’s leading technology experts to create an application that is essential for all dealerships who pride themselves on running an efficient and profitable business.`
        },
        {
          title: "How do I use iAppraise?",
          description: `Our Business Development Managers provide any necessary onsite training for all sales, management and administration staff. This support is not a one-off experience, they are available anytime to take your calls and answer any questions or concerns you may have.`
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/buttons.scss";
@import "@/scss/_variables.scss";

#home-landing {
  h1 {
    font-family: "sf_pro_displaybold";
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 76px;
  }

  h2 {
    font-family: "sf_pro_displaybold";
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
  }

  h3 {
    font-family: "sf_pro_displaybold";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
  }

  h4 {
    font-family: "sf_pro_displayregular";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
  }

  p {
    font-family: "sf_pro_displayregular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 42px;
      line-height: 100%;
    }

    h2 {
      font-size: 42px;
    }

    h3,
    h4 {
      font-size: 18px;
      line-height: 100%;
    }

    p {
      font-size: 17px;
      line-height: 140%;
    }
  }
}

.hero-banner {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/assets/img/landingpage-banner.jpg");
  background-size: cover;
  background-position: center;
}

.hero-top-subtitle {
  font-family: "sf_pro_displaybold";
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.panel-landing {
  .menu {
    .icon {
      vertical-align: middle;
      margin-right: 10px;
    }
    .sign-in {
      padding: 10px 30px;
      margin-left: 50px;

      @media (min-width: 1024px) {
        display: inline-block;
      }
    }
  }
}

.bg-light {
  background-color: #f7f7f7 !important;
  padding-bottom: 30px;
}

.features {
  font-size: 17px;
  text-align: center;

  &-item {
    padding: 0 60px 60px 0;

    @media only screen and (max-width: 600px) {
      padding: 0 0 60px 0;
    }
  }

  .title {
    font-size: 18px;
  }

  .icon {
    position: relative;
    display: inline-block;
    min-width: 100px;
    height: 100px;

    background-color: #ffe608;

    img,
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    span {
      font-size: 50px;
      text-align: center;
      font-weight: bold;
      color: #242424;
    }
  }
}

//HowItWorks-section
.how-it-works {
  .step {
    &-one,
    &-two,
    &-three,
    &-four {
      position: relative;

      img {
        transition: 1s ease-in-out;
      }

      img:hover {
        transform: translateY(-15px);
      }

      &::after {
        content: "";
        position: absolute;
        min-width: 400px;
        height: 201px;
        z-index: -1;
        visibility: visible;

        @media only screen and (max-width: 600px) {
          display: none;
        }
      }
    }

    &-one::after {
      background-image: url("/assets/img/dotted-curve.svg");
      top: 50%;
      right: 25%;
    }

    &-two::after {
      background-image: url("/assets/img/dotted-curve.svg");
      right: 20%;
      transform: rotate(121deg);
    }

    &-three::after {
      background-image: url("/assets/img/dotted-curve.svg");
      left: 12%;
      transform: rotate(-180deg);
    }
  }

  .item {
    &-first,
    &-second,
    &-third,
    &-fourth {
      padding: 20px 0 40px 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 50%;
      position: relative;
      vertical-align: middle;
      transition: color 0.5s ease-in-out;

      img {
        max-width: 60%;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 20px 0 60px 0;

        img {
          max-width: 75%;
        }
      }

      &:hover::after {
        color: $COLOUR_YELLOW;
      }

      &::after {
        font-family: "sf_pro_displayheavy";
        position: absolute;
        top: -5%;
        left: 40%;
        font-weight: 900;
        font-size: 250px;
        color: #f5f5f5;
        z-index: -1;
        visibility: visible;
        overflow: visible;
        transition: color 1s ease-in-out;

        @media only screen and (max-width: 600px) {
          font-size: 250px;
          left: 0%;
          top: 0;
        }
      }
    }

    &-first::after {
      content: "01";
    }

    &-second::after {
      content: "02";
    }

    &-third::after {
      content: "03";
    }

    &-fourth {
      padding-top: 70px;

      @media only screen and (max-width: 600px) {
        padding-top: 20px;
      }
      &::after {
        content: "04";
        top: 10px;
      }
    }
  }

  h5 {
    font-family: "sf_pro_displaybold";
    font-weight: 700;
    font-size: 40px;
    margin: 20px 0 0 0;
  }

  p {
    color: black;
  }

  .icon {
    span {
      font-family: "sf_pro_displayheavy";
      font-weight: 900;
      font-size: 96px;
      line-height: 115px;
      color: #ffe608;
    }
  }
}

//CallToAction-section
.cta-section {
  padding: 30px 120px;
  border: 9px solid #ffe608;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    padding: 30px 30px;
  }

  h5 {
    font-family: "sf_pro_displaybold";
    font-size: 64px;
    font-weight: bold;
    margin: 30px 10px;
    line-height: 80px;

    @media only screen and (max-width: 600px) {
      font-size: 36px;
      line-height: 40px;
    }
  }
}

//Quote-section
.quote-headings {
  padding: 0 30px 20px 20px;

  h5 {
    font-family: "sf_pro_displaysemibold";
    font-style: italic;
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
    margin: 50px auto;
    position: relative;

    &::after {
      content: "";
      background-image: url("/assets/img/icon-quotes.svg");
      height: 58px;
      width: 80px;
      position: absolute;
      top: -25%;
      right: 0;
      z-index: -1;
    }
  }

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
  }

  p {
    color: black;
  }
}

//testimonial-section
.testimonials-headings {
  margin-top: 100px;

  h2 {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 600px) {
    margin: 20px 0;
    padding-bottom: 0;
  }

  .testimonials-arrows {
    margin: 25% 20% 0 0;
    @media only screen and (max-width: 600px) {
      margin-top: 5%;
    }
  }
}
.testimonials-carousel {
  margin: 100px auto;
  text-align: left;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.09);

  @media only screen and (max-width: 600px) {
    margin: 20px auto;
    padding: 25px;
  }

  .item {
    font-size: 17px;
  }

  .desc {
    font-family: "sf_pro_displaythin";
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
  }

  p {
    font-family: "sf_pro_displayitalic";
    margin: 10px 0 25px 0;
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
  }

  .h4 {
    font-family: "sf_pro_displaybold";
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    padding: 10px 0;
  }
}

//FaQ-section

#faq {
  border-radius: 0;
  padding: 80px 0;

  @media only screen and (max-width: 600px) {
    padding: 10px 35px;
  }

  h4 {
    font-family: "sf_pro_displaysemibold";
  }

  h1,
  h2 {
    font-size: 64px;
    line-height: 77px;
    padding-right: 40px;

    @media only screen and (max-width: 600px) {
      font-size: 42px;
      line-height: 50px;
    }
  }

  p {
    padding: 20px 40px 10px 0;
    line-height: 140%;
  }
}

//contact-form
.home-contact {
  p {
    font-size: 24px;
  }

  .contacts {
    .icon {
      vertical-align: middle;
    }
    a {
      display: inline-block;
      margin-bottom: 5px;
      color: #000;
      text-decoration: none;
    }
  }
}

.landing-footer {
  a {
    color: #58626b;
    text-decoration: none;
  }

  p {
    color: black;
  }

  .kiratech {
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .kiratech-text {
    position: relative;
    top: -3px;
    margin-right: 7px;
  }
}

// FAQ
.accordion {
  .accordion-item {
    border: 0;

    .accordion-header {
      padding-right: 0 !important;

      .accordion-button {
        font-size: 24px;

        &:focus {
          box-shadow: none;
          border-color: rgba(0, 0, 0, 0);
        }
      }

      .accordion-button.collapsed,
      .accordion-button:not(.collapsed) {
        background-color: white !important;
        color: black !important;
        padding: 0;
        padding-left: 30px;
      }

      .accordion-button.collapsed::after,
      .accordion-button:not(.collapsed)::after {
        position: absolute;
        left: 0;
      }

      .accordion-button:not(.collapsed) {
        &::after {
          background: url("/assets/img/icon-minus.svg") 50% 50% no-repeat;
        }
      }

      .accordion-button.collapsed {
        &::after {
          background: url("/assets/img/icon-plus.svg") 50% 50% no-repeat;
        }
      }
    }

    .accordion-body {
      font-size: 18px;
      line-height: 140%;
      font-family: "sf_pro_displayregular";
      font-style: normal;
      font-weight: 400;
    }
  }
}
</style>

<style lang="scss">
@import "@/scss/_variables.scss";

// Agile
.agile__actions {
  margin-top: 50px;
}

.agile__dot {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 0;
  cursor: pointer;

  &.agile__dot--current button {
    opacity: 1;
  }

  button {
    padding: 4px;
    border: 0;
    border-radius: 100%;
    background-color: black;
    opacity: 0.25;

    &:hover {
      opacity: 1;
    }
  }
}

.agile__nav-button {
  height: 46px;
  width: 46px;
  padding: 0;
  margin: 20px 5px;
  background-color: white;
  border: 0;
  text-align: center;

  &:hover {
    background-color: $COLOUR_YELLOW;
  }
}

.agile__actions {
  justify-content: end;
}
</style>
